import Home from "pages/Home";
import upworkLogo from "assets/img/upworkLogo.png";

// css
import "assets/css/style.css";
import "assets/css/bootstrap-icons.css";
import "assets/css/swiper-bundle.min.css";

// ====== App wrapper ==========
function App() {
  return (
    <main id="smooth-wrapper">
      <Home />
      {/*  */}
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://wa.me/923300275964"
        className="fixed-whts-btn"
      >
        <i className="bi bi-whatsapp"></i>
      </a>
      {/* <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.upwork.com/freelancers/~01750d8fab22d7c49d"
        className="fixed-whts-btn fixed-upwork-btn"
      >
        <img src={upworkLogo} alt="" />
      </a> */}
    </main>
  );
}

export default App;
