import {Fade} from 'react-awesome-reveal'

const Why = () => {
    return (
        <section className="why-section" id="why-section">
            <div className="box">
                <Fade duration={900} direction="up" triggerOnce>
                <div className="section-heading sh-1" id="sh-1">
                    <h3 className="nebula-regular">WHY ME</h3>
                    <h4></h4>
                </div>
                </Fade>
                <Fade duration={1050} direction="up" triggerOnce>
                <div className="why-section-content">
                    <p>
                        Hello there, My name is Muhammad Sufiyan.
                        I am a Frontend Website Developer.
                        <br />
                        I have expertise in ReactJs, HTML, CSS, JS.
                        I have 4+ years of experience in this field.
                        <br />
                        I am also a Level 2 Seller on Fiverr, with a perfect score of
                        4.9 ratings on all projects.
                    </p>
                    To explore more further please check out my Fiverr profile and gigs or leave a message for me. Thanks.
                    <a href="https://www.fiverr.com/sufiyan222/"
                    target="_blank" rel="noopener noreferrer"
                    >More About Me <i className="bi bi-arrow-right"></i
                    ></a>
                </div>
                </Fade>
            </div>
        </section>
    )
}

export default Why