// === header & Footer
import Footer from 'layout/Footer'
import Header from 'layout/Header'
import Customer from './Customer'
import Prices from './Prices'
import Why from './Why'
// 
import gsap from "gsap"
import { ScrollTrigger } from "gsap/all";
import ScrollSmoother from "gsap/ScrollToPlugin";
import SplitText from "gsap/src/SplitText.min.js";
import { Power4 } from 'gsap';
import WebLoader from 'components/WebLoader'
import { useEffect } from 'react'

// GSAP Plugins Registration
gsap.registerPlugin(SplitText, ScrollSmoother, ScrollTrigger);


// ==== **** Home Page **** =====
const Home = () => {

  useEffect(() => {
    const loadingAnimation = () => {
      // Landing Animation
      let LoaderTl = gsap.timeline({
        paused: true,
      });

      // Text Spliting
      const HomeHeroHeading = new SplitText(".hero-section-content h1", {
        type: "chars, words",
      })
      const HomeHeroHeadingChars = HomeHeroHeading.chars;
      const HomeHeroHeadingWords = HomeHeroHeading.words;
      const HomeHeroP = new SplitText(".hero-section-content h2", {
        type: "chars, words",
      });
      const HomeHeroPChars = HomeHeroP.chars;
      const HomeHeroPWords = HomeHeroP.words;

      // loader initials
      LoaderTl.set(".logo1st", {
        y: "8px",
        x: "8px",
        opacity: 0,
      })
      LoaderTl.set(".logo2nd", {
        y: "-8px",
        x: "8px",
        scale: .3,
        opacity: 0,
      })
      LoaderTl.set(".logo3rd", {
        y: "-10px",
        x: "-8px",
        opacity: 0,
      })
      LoaderTl.set(".logoFourth", {
        y: "20px",
        scaleX: 1.7,
        x: "13px",
        opacity: 0,
      });
      // home initials
      gsap.set(".hero-heading-line-1",
        {
          right: "50%",
          opacity: 0,
        })
      gsap.set(".hero-heading-line-2",
        {
          left: "50%",
          opacity: 0,
        })
      gsap.set(
        HomeHeroHeadingChars,
        {
          opacity: 0,
          rotateY: "90deg",
        }
      )
      gsap.set(
        HomeHeroHeadingWords,
        {
          x: "5rem",
        })

      LoaderTl.to(".logo1st", {
        y: 0,
        x: 0,
        opacity: 1,
        ease: Power4.easeInOut,
        duration: .8,
      })
        .to(".logo2nd", {
          y: 0,
          x: 0,
          scale: 1,
          opacity: 1,
          ease: Power4.easeInOut,
          duration: .8
        }, "<")
        .to(".logo3rd", {
          y: 0,
          x: 0,
          opacity: 1,
          ease: Power4.easeInOut,
          duration: .8
        }, "<")
        .to(".logoFourth", {
          y: 0,
          x: 0,
          scaleX: "1",
          opacity: 1,
          ease: Power4.easeInOut,
          duration: .8
        })
        .to(".loading-logo", {
          opacity: 0,
          ease: Power4.easeInOut,
          duration: .5
        }, "=+1.4")
        .to("#home-loader",
          {
            height: 0,
            padding: 0,
            ease: Power4.easeInOut,
            opacity: 0,
            pointerEvents: "none",
            duration: .7,
            onComplete: () => document.body.classList.remove("stop_scroll")
          },
          "<"
        )
        .to(".hero-heading-line-1",
          {
            right: "-10%",
            duration: 1.15,
            opacity: 1,
            ease: Power4.easeInOut,
          },
        )
        .to(".hero-heading-line-2",
          {
            left: "10%",
            duration: 1.15,
            opacity: 1,
            ease: Power4.easeInOut,
          },
          "<"
        )
        .to(HomeHeroHeadingChars, {
          opacity: 1,
          rotateY: "0",
          duration: 1.15,
          stagger: 0.05,
          ease: Power4.easeInOut,
        },
          "<0.1"
        )
        .to(HomeHeroHeadingWords, {
          x: "0",
          duration: 1.15,
          stagger: 0.05,
          ease: Power4.easeInOut,
        },
          "<0"
        )
        .fromTo(
          HomeHeroPChars,
          {
            opacity: 0,
            rotateY: "90deg",
          },
          {
            opacity: 1,
            rotateY: "0",
            duration: 1.15,
            stagger: 0.05,
            ease: Power4.easeInOut,
          },
          "<0"
        )
        .fromTo(
          HomeHeroPWords,
          {
            x: "2.5rem",
          },
          {
            x: "0",
            duration: 1.15,
            stagger: 0.05,
            ease: Power4.easeInOut,
          },
          "<0"
        )
        .fromTo(
          ".hero-section-content a",
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.15,
            ease: Power4.easeInOut,
          },
          "<0.5"
        );
      LoaderTl.play();
    };

    loadingAnimation();

    return () => {
      loadingAnimation();
    }

  }, [])
  return (
    <div id="smooth-content">
      <Header />
      <Why />
      <Prices />
      <Customer />
      <Footer />
      <WebLoader />
    </div>
  )
}

export default Home;