import React from 'react'
import { scrollTo_Section } from 'utils/helpers'

const Footer = () => {
  return (
    <footer>
      <div className="footer-top">
        {/* <div className="box"> */}
          {/* <a href="index.html"> */}
            {/* <div className="logo-main">
              <div className="logo">
                <div className="logo-box"></div>
                <div className="logo-box"></div>
                <div className="logo-box"></div>
                <div className="logo-box"></div>
              </div>
            </div> */}
          {/* </a> */}
          {/* <p>Bringing the art to the cart.</p> */}
        {/* </div> */}
      </div>
      <div className="footer-center">
        <div className="box">
          <div className="fc-content">
            <div className="fc-top">
              <a href="tel:+923300275964"
              >Tel: <span>+92(330)-0275-964</span></a
              >
              <a href="mailto:thecodexsf@gmail.com"
              >Email: <span>thecodexsf@gmail.com</span></a
              >
              <h6>Location: <span>Karachi, Pakistan</span></h6>
            </div>
            <div className="fc-bottom">
              <div className="fcb-left">
                {/* <a
                  target="_blank" rel="noreferrer noopener"
                  href="https://twitter.com/@msufyan_22"
                ><i className="bi bi-twitter"></i>
                </a>
                <a
                  target="_blank" rel="noreferrer noopener"
                  href="https://www.instagram.com/m_sufyan_22/"
                ><i className="bi bi-instagram"></i>
                </a>
                <a
                  target="_blank" rel="noreferrer noopener"
                  href="https://www.linkedin.com/in/muhammad-sufyan-679567198"
                ><i className="bi bi-linkedin"></i>
                </a>
                <a target="_blank" rel="noreferrer noopener" href="https://github.com/M-Sufyan22">
                  <i className="bi bi-github"></i>
                </a> */}
              </div>
              <div className="fcb-center">
                <a className="nebula-regular" href="javascript:void(0)" onClick={() => scrollTo_Section("why-section")}>
                  <span>A</span>
                  <span>B</span>
                  <span>O</span>
                  <span>U</span>
                  <span>T</span>
                </a>
                <a className="nebula-regular" href="mailto:thecodexsf@gmail.com">
                  <span>C</span>
                  <span>O</span>
                  <span>N</span>
                  <span>T</span>
                  <span>A</span>
                  <span>C</span>
                  <span>T</span>
                </a>
                <a className="nebula-regular" href="javascript:void(0)" onClick={() => scrollTo_Section("customers-section")}>
                  <span>P</span>
                  <span>O</span>
                  <span>R</span>
                  <span>T</span>
                  <span>F</span>
                  <span>O</span>
                  <span>L</span>
                  <span>I</span>
                  <span>O</span>
                </a>
              </div>
              <div className="fcb-right">
                <span onClick={() => window.scroll(0,0)} className="back-to-top">
                  <i className="bi bi-caret-up"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="box">
          <div className="fb-content">
            {/* <a href="index.html"> */}
              <div className="logo-main">
                <div className="logo">
                  <div className="logo-box"></div>
                  <div className="logo-box"></div>
                  <div className="logo-box"></div>
                  <div className="logo-box"></div>
                </div>
              </div>
            {/* </a> */}
            <h6>© Copyright 2022 thecodexsf , All rights Reserved.</h6>
          </div>
        </div>
      </div>
      <div className="footer-stabalizer"></div>
    </footer>
  )
}

export default Footer