import React from 'react'
import { Fade } from 'react-awesome-reveal'

const Prices = () => {
    return (
        <section className="packages-section" id="packages-section">
            <div className="box">
                <Fade duration={900} direction="up" triggerOnce>
                    <div className="section-heading sh-2" id="sh-2">
                        <h3 className="nebula-regular">Packages</h3>
                        <h4>Bringing the art to the cart.</h4>
                    </div>
                </Fade>
                <Fade duration={1050} direction="up" triggerOnce>
                    <div className="packages-section-content">
                        <div className="packages-grid">
                            <a
                                target="_blank" rel="noreferrer noopener"
                                href="https://www.fiverr.com/share/R9pj4w"
                                className="packages-grid-card"
                            >
                                <div className="pgc-top">
                                    <h4>NFT Website</h4>
                                    <div className="logo-main">
                                        <div className="logo">
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pgc-bottom">
                                    <h5>
                                        <span>Base Price</span>
                                        $ 350
                                    </h5>
                                    <p>Order Now <i className="bi bi-bag"></i></p>
                                </div>
                            </a>
                            <a
                                target="_blank" rel="noreferrer noopener"
                                href="https://www.fiverr.com/share/bLAp5X"
                                className="packages-grid-card"
                            >
                                <div className="pgc-top">
                                    <h4>Frontend Web Dev</h4>
                                    <div className="logo-main">
                                        <div className="logo">
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                            <div className="logo-box"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pgc-bottom">
                                    <h5>
                                        <span>Base Price</span>
                                        $ 300
                                    </h5>
                                    <p>Order Now <i className="bi bi-bag"></i></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </Fade>
            </div>
        </section>
    )
}

export default Prices