  // scroll to related section
  export const scrollTo_Section = (sec_id) => {
  let element = document.getElementById(sec_id);
  var yOffset = 0;
  if (sec_id === "home") {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else {
    if (element) {
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }
};