import dragonzLabs from 'assets/img/clients/dragonzLabs.png'
import freedin from 'assets/img/clients/freedin.png'
import { Fade } from 'react-awesome-reveal'

const Customer = () => {
  return (
    <section className="customers-section" id="customers-section">
      <div className="box">
        <Fade duration={900} direction="up" triggerOnce>
          <div className="section-heading sh-3" id="sh-3">
            <h3 className="nebula-regular">CUSTOMERS</h3>
            <h4>Some of My Heart-Crafted Masterpieces</h4>
          </div>
        </Fade>
        <Fade duration={1050} direction="up" triggerOnce>
          <div className="customers-section-content">
            <div className="customers-grid">
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://doodle-apes.netlify.app/static/media/logo.aee3887755b14d9935ec.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://unusual-guests-v2.netlify.app/static/media/logo.eea3a85b.svg"
                  alt=""
                />
              </a>

              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src={freedin}
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card" style={{ background: "#323232" }}>
                <img
                  loading="lazy"
                  src="https://business-ape-club.netlify.app/static/media/logo.2e367444.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://omega-frogz.netlify.app/static/media/logo.4f4e109b1549b2146d39.png"
                  alt=""
                />
              </a>


              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://solasis-village.netlify.app/static/media/logo.7e1eb636872a7ce866f3.png"
                  alt=""
                />
              </a>

              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://octoverse.netlify.app/static/media/logo.185f6042564f9bc51994.png"
                  alt=""
                />
              </a>

              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://hood-boys.netlify.app/static/media/logo.a5d2a70741bf3442707a.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card" style={{ background: "#323232" }}>
                <img
                  loading="lazy"
                  src={dragonzLabs}
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://legendary-owlssa.netlify.app/static/media/gif.d84f680dad2ba687f57e.gif"
                  alt=""
                  style={{ borderRadius: "50%", width: "80px", height: "80px", margin: "auto" }}
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://tentai.netlify.app/static/media/logo.cdbf9d007c0c3159cc26.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://the-cooties.netlify.app/static/media/logo.effcfae2.png"
                  alt=""
                />
              </a>

              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://reindeer-app.netlify.app/static/media/newLogo.e0f79799.gif"
                  alt=""
                />
              </a>


              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://solfishjel.netlify.app/static/media/logo.83702ab13743fc9d9458.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://yetai-html.netlify.app/assets/images/others/logo.png"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card" style={{ background: "#323232" }}>
                <img
                  loading="lazy"
                  src="https://meta-leopards-club.netlify.app/static/media/logo.d64743500c1959be69037e81634d0c5d.svg"
                  alt=""
                />
              </a>
              <a href="javascript:void(0)" className="customers-grid-card">
                <img
                  loading="lazy"
                  src="https://boring-euler-b7879b.netlify.app/static/media/logo.2611ee371ebfe326ea3c.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default Customer