
// css
import "./loader.css";


// ====== Website Main Pre Loader component ======
const WebLoader = () => {
  
  return (
    <div className="loader" id="home-loader">
      <div className="logo-main loading-logo">
        <div className="logo" id="">
          <div className="logo-box logoFourth"></div>
          <div className="logo-box logo3rd"></div>
          <div className="logo-box logo2nd"></div>
          <div className="logo-box logo1st"></div>
        </div>
      </div>
      <div className="pl" id="loading-circle">
        <h3 className="pl__text nebula-regular">Loading</h3>
        <div className="pl__outer-ring"></div>
        <div className="pl__inner-ring"></div>
        <div className="pl__track-cover"></div>
        <div className="pl__ball">
          <div className="pl__ball-texture"></div>
          <div className="pl__ball-outer-shadow"></div>
          <div className="pl__ball-inner-shadow"></div>
          <div className="pl__ball-side-shadows"></div>
        </div>
      </div>
      {/* <div className="loading-box">
          <div className="logo-main">
            <div className="logo" id="loader-logo-animation">
              <div className="logo-box"></div>
              <div className="logo-box"></div>
              <div className="logo-box"></div>
              <div className="logo-box"></div>
            </div>
          </div>
          <p className="nebula-regular">LOADING ...</p>
        </div> */}
    </div>
  )
}

export default WebLoader