import mainImg from 'assets/img/saim.jpeg'
import { scrollTo_Section } from 'utils/helpers'

// ====== Hero ======
const Header = () => {
  return (
    <section className="hero-section" id="hero-section">
      <div className="box">
        <div className="hero-section-content">
          <h1 className="nebula-regular hh-d">
            <div className="hero-heading-line-1"></div>
            <div className="hero-heading-line-2"></div>
            MH Sufiyan
          </h1>
          <h1 className="nebula-regular hh-m">
            <div className="hero-heading-line-1"></div>
            <div className="hero-heading-line-2"></div>
            MH Sufiyan
          </h1>
          <h2>Client satisfaction is mandatory.</h2>
          <a
            target="_blank" rel="noreferrer noopener"
            href="https://www.fiverr.com/sufiyan222"
            className="nebula-regular"
          >
            HIRE ME !
          </a>

          <nav>
            <a className="nebula-regular" href="javascript:void(0)" onClick={() => scrollTo_Section("why-section")}>
              <span>A</span>
              <span>B</span>
              <span>O</span>
              <span>U</span>
              <span>T</span>
            </a>
            <i></i>
            <a className="nebula-regular" href="mailto:thecodexsf@gmail.com" target="_blank" rel="noopener noreferrer">
              <span>C</span>
              <span>O</span>
              <span>N</span>
              <span>T</span>
              <span>A</span>
              <span>C</span>
              <span>T</span>
            </a>
            <i></i>
            <a className="nebula-regular" href="javascript:void(0)" onClick={() => scrollTo_Section("customers-section")}>
              <span>P</span>
              <span>O</span>
              <span>R</span>
              <span>T</span>
              <span>F</span>
              <span>O</span>
              <span>L</span>
              <span>I</span>
              <span>O</span>
            </a>
          </nav>
        </div>
      </div>
      <header>
        <div className="box">
          <div className="header-content">
            <div className="header-left">
              <a href="index.html">
                <div className="logo-main">
                  <div className="logo">
                    <div className="logo-box"></div>
                    <div className="logo-box"></div>
                    <div className="logo-box"></div>
                    <div className="logo-box"></div>
                  </div>
                </div>
              </a>
            </div>
            {/* <div className="header-center">
                  <a className="nebula-regular" href="#">HOME</a>
                  <a className="nebula-regular" href="#">ABOUT</a>
                  <a className="nebula-regular" href="#">CONTACT</a>
                  <a className="nebula-regular" href="#">PORTFOLIO</a>
                </div>  */}
            <div className="header-right">
              {/* <a
                target="_blank" rel="noreferrer noopener"
                href="https://twitter.com/@msufyan_22"
              ><i className="bi bi-twitter"></i>
              </a>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://www.instagram.com/m_sufyan_22/"
              ><i className="bi bi-instagram"></i>
              </a>
              <a
                target="_blank" rel="noreferrer noopener"
                href="https://www.linkedin.com/in/muhammad-sufyan-679567198"
              ><i className="bi bi-linkedin"></i>
              </a>
              <a target="_blank" rel="noreferrer noopener" href="https://github.com/M-Sufyan22">
                <i className="bi bi-github"></i>
              </a> */}
              {/* <div className="open-mob-header">
                <div className="menu-icon">
                  <span></span>
                  <span className="menu-icon-line-small"></span>
                </div>
              </div>
              <div className="close-mob-header">
                <div className="menu-icon">
                  <span></span>
                  <span className="menu-icon-line-small"></span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="header-mob">
          <div className="box">
            <div className="header-mob-content">
              <a className="nebula-regular" href="about.html">ABOUT</a>
              <a className="nebula-regular" href="contact.html">CONTACT</a>
              <a className="nebula-regular" href="portfolio.html"
              >PORTFOLIO</a
              >
            </div>
          </div>
        </div> */}
      </header>
      {/* <div className="suifyan-box">
        <img
          className="sufiyan"
          src={"mainImg"}
          alt="Muhammad Sufiyan"
        />
      </div> */}
      {/* <div className="hero-line-1"></div> */}
      {/* <div className="hero-heading-circle">
        <div className="circular-text">
          Quality . Trust . Results . Success .
        </div>
      </div> */}
    </section>
  )
}

export default Header